var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Dla,Ela,Fla,Gla,Hla,Ila,Jla,qW,Kla,Lla,Mla,Nla,Ola,Pla,Qla,Rla,sW,Sla;$CLJS.kW=new $CLJS.N(null,"supported-field","supported-field",-2061545519);Dla=new $CLJS.N("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.lW=new $CLJS.N("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Ela=new $CLJS.N("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Fla=new $CLJS.N("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.mW=new $CLJS.N("mbql.aggregation","operator","mbql.aggregation/operator",-1481602310);$CLJS.nW=new $CLJS.N(null,"requires-column?","requires-column?",1623131448);$CLJS.oW=new $CLJS.N(null,"column-name","column-name",551523580);Gla=new $CLJS.N("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.pW=new $CLJS.N(null,"display-info","display-info",-816930907);
Hla=new $CLJS.N("mbql.clause","max","mbql.clause/max",1615385533);Ila=new $CLJS.N("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);Jla=new $CLJS.N("mbql.clause","median","mbql.clause/median",-1026386338);qW=new $CLJS.N("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Kla=new $CLJS.N("mbql.clause","min","mbql.clause/min",2074448531);Lla=new $CLJS.N("mbql.clause","percentile","mbql.clause/percentile",2064253651);
Mla=new $CLJS.N("mbql.clause","stddev","mbql.clause/stddev",659381076);Nla=new $CLJS.N("mbql.clause","var","mbql.clause/var",809434920);Ola=new $CLJS.N("mbql.clause","share","mbql.clause/share",-37921592);Pla=new $CLJS.N("mbql.clause","count","mbql.clause/count",-454225910);Qla=new $CLJS.N("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.rW=new $CLJS.N(null,"driver-feature","driver-feature",1905324248);Rla=new $CLJS.N("mbql.clause","avg","mbql.clause/avg",1826863173);
sW=new $CLJS.N("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);Sla=new $CLJS.N("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.xV($CLJS.kD,$CLJS.G([$CLJS.wu,$CLJS.hl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)],null)],null)]));$CLJS.xV($CLJS.LJ,$CLJS.G([$CLJS.wu,$CLJS.hl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)],null)],null)]));
$CLJS.vV($CLJS.BJ,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));$CLJS.vV($CLJS.LI,$CLJS.G([$CLJS.wu,$CLJS.hl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null)],null)]));$CLJS.vV($CLJS.dK,$CLJS.G([$CLJS.wu,$CLJS.hl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.HL],null)],null)]));
$CLJS.vV($CLJS.Wl,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.NL],null)],null)]));$CLJS.kV($CLJS.Wl,$CLJS.ML);$CLJS.vV($CLJS.tK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));$CLJS.kV($CLJS.tK,$CLJS.ML);$CLJS.vV($CLJS.pk,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.NL],null)],null)]));$CLJS.kV($CLJS.pk,$CLJS.ML);
$CLJS.iL(qW,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.dv,"valid percentile"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.dv,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.vV($CLJS.lJ,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,qW],null)]));$CLJS.kV($CLJS.lJ,$CLJS.ML);
$CLJS.vV($CLJS.XJ,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.HL],null)],null)]));$CLJS.vV($CLJS.yK,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));$CLJS.vV($CLJS.rK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));
$CLJS.vV($CLJS.PK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));$CLJS.kV($CLJS.rK,$CLJS.ML);$CLJS.kV($CLJS.PK,$CLJS.ML);$CLJS.vV($CLJS.MK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.HL],null)],null)]));$CLJS.kV($CLJS.MK,$CLJS.ML);
$CLJS.vV($CLJS.PI,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));$CLJS.iL(sW,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.Ak,Rla,Pla,Ila,Ela,Dla,Hla,Jla,Kla,Lla,Ola,Mla,Sla,Qla,Fla,Nla,$CLJS.Lb],null));$CLJS.iL($CLJS.lW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ll,new $CLJS.k(null,1,[$CLJS.pk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,sW],null)],null));
$CLJS.tW=new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,4,[$CLJS.zV,$CLJS.kD,$CLJS.nW,!1,$CLJS.rW,$CLJS.eI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Count of rows"),$CLJS.oW,$CLJS.UH("Count"),$CLJS.VG,$CLJS.UH("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.zV,$CLJS.rK,$CLJS.kW,$CLJS.Tl,$CLJS.nW,!0,$CLJS.rW,$CLJS.eI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Sum of ..."),$CLJS.oW,$CLJS.UH("Sum"),$CLJS.VG,$CLJS.UH("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.zV,$CLJS.BJ,$CLJS.kW,$CLJS.Tl,$CLJS.nW,!0,$CLJS.rW,$CLJS.eI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Average of ..."),$CLJS.oW,$CLJS.UH("Average"),$CLJS.VG,$CLJS.UH("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.zV,$CLJS.tK,$CLJS.kW,$CLJS.Tl,$CLJS.nW,!0,$CLJS.rW,$CLJS.DJ,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Median of ..."),$CLJS.oW,$CLJS.UH("Median"),$CLJS.VG,$CLJS.UH("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.zV,$CLJS.LI,$CLJS.kW,$CLJS.Ut,$CLJS.nW,!0,$CLJS.rW,$CLJS.eI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Number of distinct values of ..."),$CLJS.oW,$CLJS.UH("Distinct values"),$CLJS.VG,$CLJS.UH("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.zV,$CLJS.PK,$CLJS.kW,$CLJS.Tl,$CLJS.nW,!0,$CLJS.rW,$CLJS.eI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Cumulative sum of ..."),
$CLJS.oW,$CLJS.UH("Sum"),$CLJS.VG,$CLJS.UH("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.zV,$CLJS.LJ,$CLJS.nW,!1,$CLJS.rW,$CLJS.eI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Cumulative count of rows"),$CLJS.oW,$CLJS.UH("Count"),$CLJS.VG,$CLJS.UH("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.zV,$CLJS.yK,$CLJS.kW,$CLJS.Tl,$CLJS.nW,
!0,$CLJS.rW,$CLJS.jK,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Standard deviation of ..."),$CLJS.oW,$CLJS.UH("SD"),$CLJS.VG,$CLJS.UH("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.zV,$CLJS.pk,$CLJS.kW,$CLJS.Ek,$CLJS.nW,!0,$CLJS.rW,$CLJS.eI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Minimum of ..."),$CLJS.oW,$CLJS.UH("Min"),$CLJS.VG,$CLJS.UH("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.zV,$CLJS.Wl,$CLJS.kW,$CLJS.Ek,$CLJS.nW,!0,$CLJS.rW,$CLJS.eI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.SI,$CLJS.UH("Maximum of ..."),$CLJS.oW,$CLJS.UH("Max"),$CLJS.VG,$CLJS.UH("Maximum value of a column")],null)}],null)],null);
$CLJS.iL(Gla,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,$CLJS.mW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zV,$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ft],null),$CLJS.rg.g($CLJS.zV),$CLJS.tW)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kW,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,$CLJS.Dk],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nW,$CLJS.jk],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.rW,$CLJS.Dk],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pW,$CLJS.de],null)],null));