var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var zz,Wca,Xca,Cz,Yca,Fz,Gz;
$CLJS.yz=function(a,b,c){return function(){function d(q,u,v){return a.R?a.R(b,c,q,u,v):a.call(null,b,c,q,u,v)}function e(q,u){return a.D?a.D(b,c,q,u):a.call(null,b,c,q,u)}function f(q){return a.j?a.j(b,c,q):a.call(null,b,c,q)}function g(){return a.h?a.h(b,c):a.call(null,b,c)}var l=null,n=function(){function q(v,x,y,B){var H=null;if(3<arguments.length){H=0;for(var I=Array(arguments.length-3);H<I.length;)I[H]=arguments[H+3],++H;H=new $CLJS.z(I,0,null)}return u.call(this,v,x,y,H)}function u(v,x,y,B){return $CLJS.R.l(a,
b,c,v,x,$CLJS.G([y,B]))}q.v=3;q.B=function(v){var x=$CLJS.C(v);v=$CLJS.D(v);var y=$CLJS.C(v);v=$CLJS.D(v);var B=$CLJS.C(v);v=$CLJS.zd(v);return u(x,y,B,v)};q.l=u;return q}();l=function(q,u,v,x){switch(arguments.length){case 0:return g.call(this);case 1:return f.call(this,q);case 2:return e.call(this,q,u);case 3:return d.call(this,q,u,v);default:var y=null;if(3<arguments.length){y=0;for(var B=Array(arguments.length-3);y<B.length;)B[y]=arguments[y+3],++y;y=new $CLJS.z(B,0,null)}return n.l(q,u,v,y)}throw Error("Invalid arity: "+
arguments.length);};l.v=3;l.B=n.B;l.A=g;l.g=f;l.h=e;l.j=d;l.l=n.l;return l}()};zz=function(a,b){return $CLJS.F.h(a,b)?new $CLJS.S(null,3,5,$CLJS.T,[null,null,a],null):new $CLJS.S(null,3,5,$CLJS.T,[a,b,null],null)};Wca=function(a){return $CLJS.A(a)?$CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return $CLJS.U.j(b,d,c)},$CLJS.Mg($CLJS.ng($CLJS.R.h($CLJS.Gm,$CLJS.gi(a)),null)),a):null};
Xca=function(a,b,c){var d=$CLJS.M.h(a,c),e=$CLJS.M.h(b,c),f=$CLJS.Az(d,e),g=$CLJS.J(f,0,null),l=$CLJS.J(f,1,null);f=$CLJS.J(f,2,null);a=$CLJS.Ie(a,c);b=$CLJS.Ie(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.S(null,3,5,$CLJS.T,[!a||null==g&&d?null:$CLJS.Pf([c,g]),!b||null==l&&d?null:$CLJS.Pf([c,l]),d?$CLJS.Pf([c,f]):null],null)};
Cz=function(a,b){var c=$CLJS.rg.h,d=Bz.j,e=$CLJS.qe(a)?a:$CLJS.Mg(a),f=$CLJS.qe(b)?b:$CLJS.Mg(b);a=$CLJS.E(a);b=$CLJS.E(b);return $CLJS.Mg(c.call($CLJS.rg,Wca,d.call(Bz,e,f,$CLJS.Ai(0,a>b?a:b))))};Yca=function(a,b){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Rf($CLJS.Dz.h(a,b)),$CLJS.Rf($CLJS.Dz.h(b,a)),$CLJS.Rf($CLJS.Ez.h(a,b))],null)};
Fz=function(a){if(null!=a&&null!=a.Wh)a=a.Wh(a);else{var b=Fz[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Fz._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("EqualityPartition.equality-partition",a);}return a};Gz=function(a,b){if(null!=a&&null!=a.Vh)a=a.Vh(a,b);else{var c=Gz[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=Gz._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("Diff.diff-similar",a);}return a};
$CLJS.Az=function(a,b){return $CLJS.F.h(a,b)?new $CLJS.S(null,3,5,$CLJS.T,[null,null,a],null):$CLJS.F.h(Fz(a),Fz(b))?Gz(a,b):zz(a,b)};$CLJS.Dz=function Dz(a){switch(arguments.length){case 1:return Dz.g(arguments[0]);case 2:return Dz.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Dz.l(arguments[0],arguments[1],new $CLJS.z(c.slice(2),0,null))}};$CLJS.Dz.g=function(a){return a};
$CLJS.Dz.h=function(a,b){return $CLJS.E(a)<$CLJS.E(b)?$CLJS.Sb(function(c,d){return $CLJS.Ie(b,d)?$CLJS.Cm.h(c,d):c},a,a):$CLJS.Sb($CLJS.Cm,a,b)};$CLJS.Dz.l=function(a,b,c){return $CLJS.Sb($CLJS.Dz,a,$CLJS.kf.h(c,b))};$CLJS.Dz.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};$CLJS.Dz.v=2;
$CLJS.Ez=function Ez(a){switch(arguments.length){case 1:return Ez.g(arguments[0]);case 2:return Ez.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ez.l(arguments[0],arguments[1],new $CLJS.z(c.slice(2),0,null))}};$CLJS.Ez.g=function(a){return a};
$CLJS.Ez.h=function(a,b){for(;;)if($CLJS.E(b)<$CLJS.E(a)){var c=a;a=b;b=c}else return $CLJS.Sb(function(d,e){return function(f,g){return $CLJS.Ie(e,g)?f:$CLJS.Cm.h(f,g)}}(a,b),a,a)};$CLJS.Ez.l=function(a,b,c){a=$CLJS.qz(function(d){return-$CLJS.E(d)},$CLJS.kf.l(c,b,$CLJS.G([a])));return $CLJS.Sb($CLJS.Ez,$CLJS.C(a),$CLJS.zd(a))};$CLJS.Ez.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};$CLJS.Ez.v=2;var Hz=new $CLJS.N(null,"atom","atom",-397043653);var Bz=function Bz(a){switch(arguments.length){case 2:return Bz.h(arguments[0],arguments[1]);case 3:return Bz.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};Bz.h=function(a,b){return Bz.j(a,b,$CLJS.xz.h($CLJS.gi(a),$CLJS.gi(b)))};Bz.j=function(a,b,c){return $CLJS.Sb(function(d,e){return $CLJS.Ci($CLJS.rg.j($CLJS.dn,d,e))},new $CLJS.S(null,3,5,$CLJS.T,[null,null,null],null),$CLJS.rg.h($CLJS.yz(Xca,a,b),c))};Bz.v=3;
Fz["null"]=function(){return Hz};Fz.string=function(){return Hz};Fz.number=function(){return Hz};Fz.array=function(){return $CLJS.ll};Fz["function"]=function(){return Hz};Fz["boolean"]=function(){return Hz};
Fz._=function(a){return(null!=a?a.C&1024||$CLJS.t===a.Kh||(a.C?0:$CLJS.Nb($CLJS.rc,a)):$CLJS.Nb($CLJS.rc,a))?$CLJS.Vl:(null!=a?a.C&4096||$CLJS.t===a.Oh||(a.C?0:$CLJS.Nb($CLJS.vc,a)):$CLJS.Nb($CLJS.vc,a))?$CLJS.Gk:(null!=a?a.C&16777216||$CLJS.t===a.eg||(a.C?0:$CLJS.Nb($CLJS.Lc,a)):$CLJS.Nb($CLJS.Lc,a))?$CLJS.ll:Hz};Gz["null"]=function(a,b){return zz(a,b)};Gz.string=function(a,b){return zz(a,b)};Gz.number=function(a,b){return zz(a,b)};Gz.array=function(a,b){return Cz(a,b)};
Gz["function"]=function(a,b){return zz(a,b)};Gz["boolean"]=function(a,b){return zz(a,b)};Gz._=function(a,b){var c=function(){var d=Fz(a);d=d instanceof $CLJS.N?d.S:null;switch(d){case "atom":return zz;case "set":return Yca;case "sequential":return Cz;case "map":return Bz;default:throw Error(["No matching clause: ",$CLJS.p.g(d)].join(""));}}();return c.h?c.h(a,b):c.call(null,a,b)};