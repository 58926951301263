var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var pV,qV,iV,yV,nV,fla;$CLJS.jV=function(a,b){a=iV(a,b);return $CLJS.m(a)?a:$CLJS.ml};$CLJS.kV=function(a,b){$CLJS.dj.D($CLJS.CH,$CLJS.qG,a,b)};$CLJS.lV=function(a,b,c){a=$CLJS.qy.h(a,c);return a.g?a.g(b):a.call(null,b)};$CLJS.mV=function(a,b){return $CLJS.lV(a,b,null)};pV=function(){$CLJS.iL(nV,$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ft],null),$CLJS.Pe($CLJS.Le,$CLJS.r(oV))))};
qV=function(){return $CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,2,[$CLJS.vk,$CLJS.C,$CLJS.Wu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.pl);return $CLJS.qe(a)?["Invalid ",$CLJS.aj.l($CLJS.G([$CLJS.C(a)]))," clause: ",$CLJS.aj.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null)],null),$CLJS.rg.g(function(a){return new $CLJS.S(null,2,5,$CLJS.T,[a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.Oi.h("mbql.clause",$CLJS.ui(a))],null)],null)}),$CLJS.r(oV))};
$CLJS.rV=function(a,b){var c=$CLJS.Oi.h("mbql.clause",$CLJS.ui(a));$CLJS.iL(c,b);$CLJS.Ie($CLJS.r(oV),a)||$CLJS.dj.j(oV,$CLJS.kf,a);return null};$CLJS.sV=function(a,b,c){$CLJS.rV(a,c);$CLJS.zL.o(null,a,function(){return b});return null};$CLJS.vV=function(a,b){$CLJS.R.D(tV,uV,a,b)};$CLJS.xV=function(a,b){$CLJS.R.D(tV,wV,a,b)};
iV=function iV(a,b){return $CLJS.F.h(a,$CLJS.ml)?null:$CLJS.F.h(b,$CLJS.ml)?null:$CLJS.NH(a,b)?b:$CLJS.NH(b,a)?a:$CLJS.Xf(function(d){return $CLJS.Xf(function(e){return $CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[d,e],null),new $CLJS.S(null,2,5,$CLJS.T,[a,b],null))?null:iV.h?iV.h(d,e):iV.call(null,d,e)},$CLJS.nf(b,$CLJS.oj(b)))},$CLJS.nf(a,$CLJS.oj(a)))};yV=new $CLJS.N("metabase.lib.schema.mbql-clause","clause*","metabase.lib.schema.mbql-clause/clause*",1127493678);
$CLJS.zV=new $CLJS.N(null,"short","short",1928760516);nV=new $CLJS.N("metabase.lib.schema.mbql-clause","tag","metabase.lib.schema.mbql-clause/tag",-405459143);$CLJS.AV=new $CLJS.N("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);fla=new $CLJS.N("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);var oV=$CLJS.cj.g($CLJS.oi);$CLJS.EH(oV,fla,function(){pV();return $CLJS.iL(yV,qV())});pV();$CLJS.iL(yV,qV());$CLJS.iL($CLJS.BL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,nV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,$CLJS.Lb],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,yV],null)],null));
var wV=function wV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
wV.l=function(a,b){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,$CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zt,new $CLJS.k(null,1,[$CLJS.dv,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,a],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.vL],null)],null)],null)],null),b)],null)};wV.v=1;
wV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};var uV=function uV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return uV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
uV.l=function(a,b){return $CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.vL],null)],null),b)};uV.v=1;uV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var tV=function tV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return tV.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};tV.l=function(a,b,c){c=$CLJS.F.h($CLJS.C(c),$CLJS.wu)?$CLJS.nf($CLJS.Zd(c),$CLJS.kg(2,c)):$CLJS.nf(null,c);var d=$CLJS.A(c);c=$CLJS.C(d);d=$CLJS.D(d);a=$CLJS.R.j(a,b,d);return $CLJS.m(c)?$CLJS.sV(b,c,a):$CLJS.rV(b,a)};tV.v=2;
tV.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};