var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var mX,nX,yma,zma,Ama,pX,qX,rX,sX,uX,Bma,wX;mX=function(a){switch(arguments.length){case 2:return $CLJS.mV(arguments[0],arguments[1]);case 3:return $CLJS.lV(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};nX=function(a){return["(?:",$CLJS.p.g($CLJS.R.h($CLJS.p,a)),")?"].join("")};yma=new $CLJS.N("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
zma=new $CLJS.N("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.oX=new $CLJS.N("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);Ama=new $CLJS.N("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);pX=new $CLJS.N("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
qX=new $CLJS.N("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);rX=new $CLJS.N("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);sX=new $CLJS.N("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.tX=new $CLJS.N("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
uX=new $CLJS.N("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Bma=new $CLJS.N("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.vX=new $CLJS.N("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);wX=new $CLJS.N("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.xX=new $CLJS.N("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.zL.o(null,$CLJS.FG,function(){return $CLJS.ml});$CLJS.iL(wX,$CLJS.jk);$CLJS.zL.o(null,$CLJS.LG,function(){return $CLJS.yl});$CLJS.iL(wX,$CLJS.jk);$CLJS.iL(Bma,$CLJS.Qt);$CLJS.zL.o(null,$CLJS.uG,function(){return $CLJS.hl});$CLJS.iL(zma,$CLJS.Pt);$CLJS.zL.o(null,$CLJS.sG,function(){return $CLJS.JK});$CLJS.iL(Ama,$CLJS.Ml);
var Cma=[":\\d{2}",nX($CLJS.G(["\\.\\d{1,6}"]))].join(""),yX=["\\d{2}:\\d{2}",nX($CLJS.G([Cma]))].join(""),zX=["\\d{4}-\\d{2}-\\d{2}T",yX].join(""),AX=["(?:Z|(?:[+-]",yX,"))"].join(""),Dma=$CLJS.Fi("^\\d{4}-\\d{2}-\\d{2}$"),Ema=$CLJS.Fi(["^",yX,"$"].join("")),Fma=$CLJS.Fi(["^",yX,AX,"$"].join("")),Gma=$CLJS.Fi(["^",zX,"$"].join("")),Hma=$CLJS.Fi(["^",zX,AX,"$"].join(""));$CLJS.iL(rX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.dv,"date string literal"],null),Dma],null));
$CLJS.iL(qX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ak,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.dv,"local time string literal"],null),Ema],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.dv,"offset time string literal"],null),Fma],null)],null));
$CLJS.iL(sX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ak,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.dv,"local date time string literal"],null),Gma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.dv,"offset date time string literal"],null),Hma],null)],null));
$CLJS.zL.o(null,$CLJS.tG,function(a){return $CLJS.m($CLJS.mV?$CLJS.mV(sX,a):mX.call(null,sX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Ql,null,$CLJS.RJ,null],null),null):$CLJS.m($CLJS.mV?$CLJS.mV(rX,a):mX.call(null,rX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.NI,null,$CLJS.Ql,null],null),null):$CLJS.m($CLJS.mV?$CLJS.mV(qX,a):mX.call(null,qX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.WK,null,$CLJS.Ql,null],null),null):$CLJS.Ql});$CLJS.iL($CLJS.tX,rX);$CLJS.iL(pX,qX);
$CLJS.iL($CLJS.xX,sX);$CLJS.iL(yma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ak,$CLJS.tX,pX,$CLJS.xX],null));$CLJS.BX=$CLJS.Fi("^\\d{4}-\\d{2}$");$CLJS.iL($CLJS.vX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.dv,"year-month string literal"],null),$CLJS.BX],null));$CLJS.CX=$CLJS.Fi("^\\d{4}$");$CLJS.iL($CLJS.oX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.dv,"year string literal"],null),$CLJS.CX],null));
$CLJS.iL(uX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.vL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kk,$CLJS.wL],null)],null)],null));$CLJS.rV($CLJS.pl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"Value :value clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,$CLJS.pl],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,uX],null),$CLJS.Lb],null));