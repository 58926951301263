var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Ioa,a0,Joa,Koa,Moa,Loa;$CLJS.X_=function(a,b){return $CLJS.R.j($CLJS.xa,a,b)};Ioa=function(a){var b=new $CLJS.rb,c=$CLJS.Rz;$CLJS.Rz=new $CLJS.gd(b);try{var d=$CLJS.Rz,e=$CLJS.Gb($CLJS.ZA(d)),f=$CLJS.Rz;$CLJS.Rz=e?$CLJS.QA(d):d;try{d=$CLJS.aB;$CLJS.aB=!0;try{$CLJS.cB(a)}finally{$CLJS.aB=d}$CLJS.F.h(0,$CLJS.Yz($CLJS.Rz,$CLJS.aA))||$CLJS.Qc($CLJS.Rz,"\n");$CLJS.Xz()}finally{$CLJS.Rz=f}$CLJS.Nz($CLJS.p.g(b))}finally{$CLJS.Rz=c}};
$CLJS.Y_=function(a){var b=new $CLJS.rb,c=$CLJS.Ab;$CLJS.Ab=function(d){return b.append(d)};try{Ioa(a)}finally{$CLJS.Ab=c}return $CLJS.p.g(b)};$CLJS.Z_=function(a){return $CLJS.qe(a)&&1<$CLJS.E(a)&&$CLJS.C(a)instanceof $CLJS.N&&$CLJS.oe($CLJS.Zd(a))&&$CLJS.Ie($CLJS.Zd(a),$CLJS.HG)};$CLJS.$_=function(a){return $CLJS.Z_(a)?$CLJS.Iu(a,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.KL],null)):null};
a0=function(a,b,c){var d=$CLJS.Ie(a,b)?$CLJS.Om.j(a,b,function(e){return $CLJS.qe(e)&&$CLJS.F.h($CLJS.C(e),$CLJS.Lj)?$CLJS.Mg($CLJS.kg(1,e)):new $CLJS.S(null,1,5,$CLJS.T,[e],null)}):a;return $CLJS.Ie(a,b)?$CLJS.ON(d,$CLJS.Pf([b,c])):d};Joa=function(a){var b=$CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XP,$CLJS.jR],null));b=b0.g?b0.g(b):b0.call(null,b);return $CLJS.eW($CLJS.U.l(a0($CLJS.Bm.l(a,$CLJS.XP,$CLJS.G([$CLJS.jR])),$CLJS.ZR,$CLJS.jZ),$CLJS.uz,$CLJS.bZ,$CLJS.G([$CLJS.gZ,b])))};
Koa=function(a){return $CLJS.Nm.h(Joa,a)};Moa=function(a){return $CLJS.m(a)?$CLJS.U.j($CLJS.Om.j($CLJS.xe(a)?new $CLJS.k(null,1,[$CLJS.c0,a],null):a,$CLJS.c0,function(b){return $CLJS.Nm.h(function(c){return $CLJS.U.j($CLJS.bG(c,$CLJS.nG),$CLJS.uz,$CLJS.gW)},b)}),$CLJS.uz,Loa):null};
$CLJS.d0=function(a){if($CLJS.F.h($CLJS.uz.g(a),$CLJS.LZ))return a;var b=$CLJS.Vk.g(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return $CLJS.dn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.uz,$CLJS.LZ,$CLJS.gZ,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.dn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.uz,$CLJS.HZ],null),$CLJS.ON($CLJS.SQ.g(a),new $CLJS.k(null,1,[$CLJS.tO,$CLJS.SQ],null))]))],null)],null),$CLJS.Bm.l(a,$CLJS.Vk,$CLJS.G([$CLJS.SQ]))]));case "query":return $CLJS.dn.l($CLJS.G([new $CLJS.k(null,
2,[$CLJS.uz,$CLJS.LZ,$CLJS.gZ,b0($CLJS.tO.g(a))],null),$CLJS.Bm.l(a,$CLJS.Vk,$CLJS.G([$CLJS.tO]))]));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.e0=function(a){return"string"===typeof a&&(a=$CLJS.Ei(/^card__(\d+)$/,a),$CLJS.m(a))?($CLJS.J(a,0,null),a=$CLJS.J(a,1,null),$CLJS.nL(a)):null};$CLJS.f0=new $CLJS.N("lib","source","lib/source",-434086550);$CLJS.g0=new $CLJS.N("metadata","segment","metadata/segment",-1286738015);
$CLJS.h0=new $CLJS.N("metadata","table","metadata/table",-882499405);$CLJS.Noa=new $CLJS.N(null,"num-stages","num-stages",1426797535);$CLJS.i0=new $CLJS.N(null,"tables","tables",1334623052);$CLJS.j0=new $CLJS.N(null,"table-id","table-id",-766649466);$CLJS.c0=new $CLJS.N(null,"columns","columns",1998437288);$CLJS.Ooa=new $CLJS.N("metadata","database","metadata/database",-924828824);$CLJS.k0=new $CLJS.N(null,"table","table",-564943036);$CLJS.l0=new $CLJS.N("metadata","card","metadata/card",-1039333889);
Loa=new $CLJS.N("metadata","results","metadata/results",330329298);var b0;
b0=function b0(a){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.jR),d=$CLJS.M.h(a,$CLJS.MP);c=$CLJS.m(c)?b0.g?b0.g(c):b0.call(null,c):$CLJS.Lg;d=Moa(d);var e=$CLJS.E(c)-1;c=$CLJS.m($CLJS.m(d)?!(0>e):d)?$CLJS.Ou(c,new $CLJS.S(null,2,5,$CLJS.T,[e,$CLJS.nZ],null),d):c;d=$CLJS.m($CLJS.SQ.g(a))?$CLJS.HZ:$CLJS.BZ;a=$CLJS.dn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.uz,d],null),$CLJS.Bm.l(a,$CLJS.jR,$CLJS.G([$CLJS.MP]))]));a=$CLJS.A($CLJS.TO.g(a))?$CLJS.Om.j(a,$CLJS.TO,Koa):a;a=a0(a,$CLJS.HO,$CLJS.MZ);return $CLJS.kf.h(c,a)};
$CLJS.m0=RegExp(" id$","i");